const GC_ENVIRONMENT = 'genesyscloud-env';
const GC_TOKEN = 'genesyscloud-token';
const GC_DIVISION_ID = 'division-id';
const GC_ACCESS_TOKEN = 'access_token';

const R2S_CONVERSATIONS = 'r2s-conversations' ;
const R2S_USER_ROLE = 'r2s-user-role' ;
const R2S_SEARCH_CRITERIA ='r2s-conversations-search-criteria' ;


const GC_ENV_US_EAST_1 ='mypurecloud.com';
const GC_ENV_US_EAST_2 ='use2.us-gov-pure.cloud';
const GC_ENV_US_WEST_2 ='usw2.pure.cloud';
const GC_ENV_CA_CENTRAL_1 ='cac1.pure.cloud';
const GC_ENV_EU_CENTRAL_1 ='mypurecloud.de'; 
const GC_ENV_EU_WEST_1 ='mypurecloud.ie';
const GC_ENV_EU_WEST_2 ='euw2.pure.cloud'; 
const GC_ENV_AP_SOUTH_1 ='aps1.pure.cloud';
const GC_ENV_AP_NORTHEAST_2 ='apne2.pure.cloud';
const GC_ENV_AP_SOUTHEAST_2 ='mypurecloud.com.au';
const GC_ENV_AP_NORTHEAST_1 ='mypurecloud.jp';

const R2S_INTEGRATION_TYPE = 'premium-app-r2s';
const R2S_INTEGRATION_TYPE_DEV = 'premium-app-example';

const GC_CLIENT_ID = '969eb261-faa1-4ea9-ad3d-84440124eb5c';

const GC_LANG_TAG = 'en-us';

const REACT_ENV_PROD = 'production';
const REACT_ENV_DEV = 'development';
const REACT_ENV_TEST = 'test';

const R2S_USER_ROLE_DEV = 'R2S_USER_ROLE';
const R2S_USER_ROLE_TEST = 'R2S_USER_ROLE';
const R2S_USER_ROLE_PROD = 'R2S_USER_ROLE';


const SEARCH_PARAM_ANI = 'ani';
const SEARCH_PARAM_DNIS = 'dnis';
const SEARCH_PARAM_AGENT = 'agent';
const SEARCH_PARAM_DIRECTION = 'conv_direction';
const SEARCH_PARAM_TITLES = 'titles';
const SEARCH_PARAM_DEPARTMENT = 'departments';
const SEARCH_PARAM_QUEUES = 'queues';
const SEARCH_PARAM_DIVISION= 'division';
const SEARCH_PARAM_REGION= 'region';
const SEARCH_PARAM_ENDDT= 'endDateTime';
const SEARCH_PARAM_STARTDT= 'startDateTime';
const SEARCH_PARAM_CONVERSATIONID= 'conversationID';
const SEARCH_PARAM_LOCATIONS = 'locations';


const SESSION_KEY_AUDIT = 'r2s-audit';
const SESSION_KEY_CONSENTAGREED = 'consentAgreed';
const SESSION_KEY_USER_LOGIN_RESPONSE = 'r2s-userLoginResponse';
const SESSION_KEY_DISCLAIMER_RESPONSE = 'r2s-disclaimerResponse';
const SESSION_KEY_TERMS = 'r2s-terms';
const SESSION_KEY_AVAILABLE_ATTRIBUTES = 'r2s-availableAttributes';
const SESSION_KEY_CONVERSATIONS_PAGESIZE = 'r2s-conversations-pageSize';
const SESSION_KEY_GETUSER_ME_RESPONSE = 'r2s-getUserMeResponse';
const SESSION_KEY_GETUSERS_RESPONSE = 'r2s-getUsersResponse';
const SESSION_KEY_GETALLUSERS_RESPONSE = 'r2s-allUserResponse';
const SESSION_KEY_GETUSER_BY_ID_RESPONSE ='r2s-getUserbyIDResponse'





module.exports = {
    SEARCH_PARAM_ANI,
    SEARCH_PARAM_DNIS,
    SEARCH_PARAM_AGENT,
    SEARCH_PARAM_TITLES,
    SEARCH_PARAM_DEPARTMENT,
    SEARCH_PARAM_QUEUES,
    SEARCH_PARAM_DIVISION,
    SEARCH_PARAM_REGION,
    SEARCH_PARAM_ENDDT,
    SEARCH_PARAM_STARTDT,
    SEARCH_PARAM_CONVERSATIONID,
    SEARCH_PARAM_LOCATIONS,
    SEARCH_PARAM_DIRECTION,
    GC_ENVIRONMENT,
    GC_TOKEN,
    GC_ACCESS_TOKEN,
    GC_DIVISION_ID,
    GC_ENV_US_EAST_1,
    GC_ENV_US_EAST_2,
    GC_ENV_US_WEST_2,
    GC_ENV_CA_CENTRAL_1,
    GC_ENV_EU_CENTRAL_1,
    GC_ENV_EU_WEST_1,
    GC_ENV_EU_WEST_2,
    GC_ENV_AP_SOUTH_1,
    GC_ENV_AP_NORTHEAST_2,
    GC_ENV_AP_SOUTHEAST_2,
    GC_ENV_AP_NORTHEAST_1,
    R2S_INTEGRATION_TYPE,
    GC_CLIENT_ID,
    GC_LANG_TAG,
    REACT_ENV_PROD,
    REACT_ENV_TEST,
    REACT_ENV_DEV,
    R2S_INTEGRATION_TYPE_DEV,
    R2S_USER_ROLE_DEV,
    R2S_USER_ROLE_TEST,
    R2S_USER_ROLE_PROD,
    R2S_CONVERSATIONS,
    R2S_SEARCH_CRITERIA,
    R2S_USER_ROLE,
    SESSION_KEY_AUDIT,
    SESSION_KEY_CONSENTAGREED,
    SESSION_KEY_USER_LOGIN_RESPONSE,
    SESSION_KEY_DISCLAIMER_RESPONSE,
    SESSION_KEY_TERMS,
    SESSION_KEY_AVAILABLE_ATTRIBUTES,
    SESSION_KEY_CONVERSATIONS_PAGESIZE,
    SESSION_KEY_GETUSER_ME_RESPONSE,
    SESSION_KEY_GETUSERS_RESPONSE,
    SESSION_KEY_GETALLUSERS_RESPONSE,
    SESSION_KEY_GETUSER_BY_ID_RESPONSE


}