import React, { Fragment } from 'react';
import {
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap'
import './Header.css'

const Header = (props) => {
    console.log(`header :: ${props.role}`);
    return (
        <div>
            <Navbar dark expand="md" className="header">
                <Nav className="container-fluid" navbar>
                    <NavItem>
                        <NavbarBrand href="/conversations">Recording As A Service</NavbarBrand>
                    </NavItem>
                    <div class="d-flex flex-row-reverse">
                    <NavItem className='ml-auto'>
                        <NavLink href="/conversations">Conversations</NavLink>
                    </NavItem>
                    {props.role === 'admin' ?
                        (<Fragment><UncontrolledDropdown
                            nav
                            inNavbar
                        >
                            <DropdownToggle nav caret>
                                Configuration
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem>
                                    <NavLink href="/management/users/">Users</NavLink>
                                </DropdownItem>
                                <DropdownItem>
                                    <NavLink href="/management/searchview">Search View</NavLink>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                            
                        </Fragment>)
                        : <Fragment></Fragment>}
                        {props.role === 'admin' ?
                        (

                        <Fragment>
                            <UncontrolledDropdown
                                nav
                                inNavbar
                            >
                                
                                <NavItem>
                                        <NavLink href="/audits/access/">Audit Reports</NavLink>
                                 </NavItem>
                            </UncontrolledDropdown></Fragment>
                            )
                            : <Fragment></Fragment>}
                        </div>
                </Nav>
            </Navbar>
        </div>
    );
}

export default Header;